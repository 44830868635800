import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    PlusCircleOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    Col, Form, InputNumber, Row, Spin, Tooltip
} from 'antd'

import { useClassName } from 'utils/cn'

import { Button } from '../../components'

import { actions, selectors } from '../../models'

import './style.less'

import Modal from './modal'
import Fertilizer from './fertilizer'
import FertilizersTable from './fertilizers-table'
import { toFixed } from '../../utils/utils'
import { curDistributionType } from '../../reducers/view/selectors'

const {
    isLoadingFertilizer,
    isCalculateCompleted
} = selectors

const {
    addDistribution,
    updateDistribution,
    getFertilizer
} = actions


export default ({ distributionType, calculator }) => {
    distributionType = distributionType && useSelector(state => curDistributionType(state))
    const isCompleted = useSelector(state => isCalculateCompleted(state))
    const { normative, nutrition, rb, distribution } = calculator

    const loadingFertilizer = useSelector(state => isLoadingFertilizer(state))
    const dispatch = useDispatch()
    const { t } = useTranslation('distribution')
    const cn = useClassName('calculator')
    const match = useRouteMatch()
    const { params: { id, type } } = match
    const history = useHistory()
    const [form] = Form.useForm()
    const [visibleFertilizer, setVisibleFertilizer] = useState(false)
    const [visibleFertilizersTable, setVisibleFertilizersTable] = useState(false)
    const [selectedFertilizers, setSelectedFertilizers] = useState([])
    const [distributionFertilizers, setDistributionFertilizers] = useState([])
    const [totalDistributionFertilizers, setTotalDistributionFertilizers] = useState([])

    useEffect(() => {
        if (distributionType) {
            onSubmit([...selectedFertilizers])
        }
    }, [distributionType])

    useEffect(() => {
        const { related_fertilizers } = distribution
        const values = {}
        related_fertilizers?.forEach(fertilizer => {
            values[`${fertilizer.fertilizer.id}_main`] = fertilizer.main
            values[`${fertilizer.fertilizer.id}_sowing`] = fertilizer.sowing
            values[`${fertilizer.fertilizer.id}_feeding`] = fertilizer.feeding
        })
        form.setFieldsValue(values)
        const fertilizers = []
        related_fertilizers?.forEach(fertilizer => {
            fertilizers.push({
                main: fertilizer.main,
                sowing: fertilizer.sowing,
                feeding: fertilizer.feeding,
                total: fertilizer.tolal,
                ...fertilizer.fertilizer
            })
        })
        setSelectedFertilizers([...fertilizers])
        const recommended = getRecommended()
        const distributionWithProps = { ...distribution, ...recommended }

        setDistributionFertilizers(converterFertilizer(distributionWithProps))
        setTotalDistributionFertilizers(totalFertilizer(distributionWithProps))
    }, [distribution])

    const setFertilizers = (fertilizers) => {
        onSubmit(fertilizers)
    }

    const converterFertilizer = (distribution) => {
        const arr = []
        STEPS.forEach(step => {
            const total = { actual_n: 0, actual_p: 0, actual_k: 0 }
            const fertilizers = []
            fertilizers.push({
                id: 0,
                name: t('recommended'),
                type: 'row-bold',
                actual_n: toFixed(distribution[`recommended_n_${step}`])?.toLocaleString('ru-RU'),
                actual_p: toFixed(distribution[`recommended_p_${step}`])?.toLocaleString('ru-RU'),
                actual_k: toFixed(distribution[`recommended_k_${step}`])?.toLocaleString('ru-RU')
            })
            distribution.related_fertilizers?.forEach(fertilizer => {
                const curFertilizer = fertilizer.fertilizer
                if (curFertilizer.id) {
                    const actual = {};
                    ['n', 'p', 'k'].forEach(item => {
                        actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer[step]) / 100) ?? 0
                        total[`actual_${item}`] += actual[`actual_${item}`]
                    })
                    fertilizers.push({
                        id: curFertilizer.id,
                        name: curFertilizer.name,
                        actual_n: toFixed(actual?.actual_n)?.toLocaleString('ru-RU'),
                        actual_p: toFixed(actual?.actual_p)?.toLocaleString('ru-RU'),
                        actual_k: toFixed(actual?.actual_k)?.toLocaleString('ru-RU')
                    })
                }
            })
            if (Math.round(total.actual_n) > Math.round(distribution[`recommended_n_${step}`])) {
                total.actual_n = <>{toFixed(total?.actual_n)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_n} normal={distribution[`recommended_n_${step}`]} /></>
            } else {
                total.actual_n = toFixed(total.actual_n)?.toLocaleString('ru-RU')
            }
            if (Math.round(total.actual_p) > Math.round(distribution[`recommended_p_${step}`])) {
                total.actual_p = <>{toFixed(total?.actual_p)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_p} normal={distribution[`recommended_p_${step}`]} /></>
            } else {
                total.actual_p = toFixed(total.actual_p)?.toLocaleString('ru-RU')
            }
            if (Math.round(total.actual_k) > Math.round(distribution[`recommended_k_${step}`])) {
                total.actual_k = <>{toFixed(total?.actual_k)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_k} normal={distribution[`recommended_k_${step}`]} /></>
            } else {
                total.actual_k = toFixed(total.actual_k)?.toLocaleString('ru-RU')
            }
            fertilizers.push({
                id: 0, type: 'row-bold', name: t('total'), ...total
            })
            arr.push({ title: step, fertilizers })
        })
        return arr
    }

    const totalFertilizer = (distribution) => {
        const arr = []
        const fertilizers = []
        const totalRecommended = { actual_n: 0, actual_p: 0, actual_k: 0 }
        const total = { actual_n: 0, actual_p: 0, actual_k: 0 }
        STEPS.forEach(step => {
            totalRecommended.actual_n += distribution[`recommended_n_${step}`]
            totalRecommended.actual_p += distribution[`recommended_p_${step}`]
            totalRecommended.actual_k += distribution[`recommended_k_${step}`]
        })
        fertilizers.push({
            id: 0,
            type: 'row-bold-green',
            name: t('recommended'),
            actual_n: toFixed(totalRecommended?.actual_n)?.toLocaleString('ru-RU'),
            actual_p: toFixed(totalRecommended?.actual_p)?.toLocaleString('ru-RU'),
            actual_k: toFixed(totalRecommended?.actual_k)?.toLocaleString('ru-RU')
        })
        distribution.related_fertilizers?.forEach(fertilizer => {
            const curFertilizer = fertilizer.fertilizer
            if (curFertilizer.id) {
                const actual = {};
                ['n', 'p', 'k'].forEach(item => {
                    actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer.total) / 100) ?? 0
                    total[`actual_${item}`] += actual[`actual_${item}`]
                })
                fertilizers.push({
                    id: curFertilizer.id,
                    name: curFertilizer.name,
                    total: fertilizer.total,
                    actual_n: toFixed(actual?.actual_n)?.toLocaleString('ru-RU'),
                    actual_p: toFixed(actual?.actual_p)?.toLocaleString('ru-RU'),
                    actual_k: toFixed(actual?.actual_k)?.toLocaleString('ru-RU')
                })
            }
        })
        if (Math.round(total.actual_n) > Math.round(totalRecommended.actual_n)) {
            total.actual_n = <>{toFixed(total?.actual_n)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_n} normal={totalRecommended.actual_n} /></>
        } else {
            total.actual_n = toFixed(total.actual_n)?.toLocaleString('ru-RU')
        }
        if (Math.round(total.actual_p) > Math.round(totalRecommended.actual_p)) {
            total.actual_p = <>{toFixed(total?.actual_p)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_p} normal={totalRecommended.actual_p} /></>
        } else {
            total.actual_p = toFixed(total.actual_p)?.toLocaleString('ru-RU')
        }
        if (Math.round(total.actual_k) > Math.round(totalRecommended.actual_k)) {
            total.actual_k = <>{toFixed(total?.actual_k)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_k} normal={totalRecommended.actual_k} /></>
        } else {
            total.actual_k = toFixed(total.actual_k)?.toLocaleString('ru-RU')
        }
        fertilizers.push({
            id: 0, type: 'row-bold-green', name: t('total'), ...total
        })
        arr.push({ title: t('total'), fertilizers })
        return arr
    }

    const onValuesChange = (value, values) => {
        const fertilizers = selectedFertilizers.map(fertilizer => ({
            fertilizer: fertilizer.id,
            main: values[`${fertilizer.id}_main`] ?? fertilizer.main,
            sowing: values[`${fertilizer.id}_sowing`] ?? fertilizer.sowing,
            feeding: values[`${fertilizer.id}_feeding`] ?? fertilizer.feeding
        }))
        const recommended = getRecommended()
        const sendValues = {
            // distribution_method: 1,
            fertilizers,
            ...recommended
        }
        dispatch(updateDistribution({ id: distribution.id, values: sendValues }))
    }

    const showModal = () => {
        setVisibleFertilizersTable(true)
    }

    const showFertilizerInfo = (fertilizerId) => {
        if (!isCompleted) {
            dispatch(getFertilizer(fertilizerId))
            setVisibleFertilizer(true)
        }
    }

    const deleteFertilizer = (fertilizerId) => {
        if (!isCompleted) {
            const fertilizers = [...selectedFertilizers]
            const indexOfDeleted = fertilizers.findIndex(item => item.id === fertilizerId)
            fertilizers.splice(indexOfDeleted, 1)
            onSubmit(fertilizers)
        }
    }

    const hideModalFertilizer = () => {
        setVisibleFertilizer(false)
    }

    const hideModalFertilizersTable = () => {
        setVisibleFertilizersTable(false)
    }

    const closeCalculator = () => {
        history.push('/calculators')
    }

    const getRecommended = () => {
        if (distributionType) {
            switch (distributionType) {
                case 'normative':
                    {
                        return {
                            recommended_n_main: normative.recommended_n_main,
                            recommended_p_main: normative.recommended_p_main,
                            recommended_k_main: normative.recommended_k_main,
                            recommended_n_sowing: normative.recommended_n_sowing,
                            recommended_p_sowing: normative.recommended_p_sowing,
                            recommended_k_sowing: normative.recommended_k_sowing,
                            recommended_n_feeding: normative.recommended_n_feeding,
                            recommended_p_feeding: normative.recommended_p_feeding,
                            recommended_k_feeding: normative.recommended_k_feeding
                        }
                    }
                case 'expert':
                    {
                        return {
                            recommended_n_main: normative.expert_n_main,
                            recommended_p_main: normative.expert_p_main,
                            recommended_k_main: normative.expert_k_main,
                            recommended_n_sowing: normative.expert_n_sowing,
                            recommended_p_sowing: normative.expert_p_sowing,
                            recommended_k_sowing: normative.expert_k_sowing,
                            recommended_n_feeding: normative.expert_n_feeding,
                            recommended_p_feeding: normative.expert_p_feeding,
                            recommended_k_feeding: normative.expert_k_feeding
                        }
                    }
                case 'nutrition':
                    {
                        return {
                            recommended_n_main: nutrition.recommended_n_main,
                            recommended_p_main: nutrition.recommended_p_main,
                            recommended_k_main: nutrition.recommended_k_main,
                            recommended_n_sowing: nutrition.recommended_n_sowing,
                            recommended_p_sowing: nutrition.recommended_p_sowing,
                            recommended_k_sowing: nutrition.recommended_k_sowing,
                            recommended_n_feeding: nutrition.recommended_n_feeding,
                            recommended_p_feeding: nutrition.recommended_p_feeding,
                            recommended_k_feeding: nutrition.recommended_k_feeding
                        }
                    }
                default: {
                    return {
                        recommended_n_main: rb.recommended_n_main,
                        recommended_p_main: rb.recommended_p_main,
                        recommended_k_main: rb.recommended_k_main,
                        recommended_n_sowing: rb.recommended_n_sowing,
                        recommended_p_sowing: rb.recommended_p_sowing,
                        recommended_k_sowing: rb.recommended_k_sowing,
                        recommended_n_feeding: rb.recommended_n_feeding,
                        recommended_p_feeding: rb.recommended_p_feeding,
                        recommended_k_feeding: rb.recommended_k_feeding
                    }
                }
            }
        } else {
            switch (Number(type)) {
                case 11:
                    {
                        return {
                            recommended_n_main: nutrition.recommended_n_main,
                            recommended_p_main: nutrition.recommended_p_main,
                            recommended_k_main: nutrition.recommended_k_main,
                            recommended_n_sowing: nutrition.recommended_n_sowing,
                            recommended_p_sowing: nutrition.recommended_p_sowing,
                            recommended_k_sowing: nutrition.recommended_k_sowing,
                            recommended_n_feeding: nutrition.recommended_n_feeding,
                            recommended_p_feeding: nutrition.recommended_p_feeding,
                            recommended_k_feeding: nutrition.recommended_k_feeding
                        }
                    }
                case 6:
                    {
                        return {
                            recommended_n_main: normative.recommended_n_main,
                            recommended_p_main: normative.recommended_p_main,
                            recommended_k_main: normative.recommended_k_main,
                            recommended_n_sowing: normative.recommended_n_sowing,
                            recommended_p_sowing: normative.recommended_p_sowing,
                            recommended_k_sowing: normative.recommended_k_sowing,
                            recommended_n_feeding: normative.recommended_n_feeding,
                            recommended_p_feeding: normative.recommended_p_feeding,
                            recommended_k_feeding: normative.recommended_k_feeding
                        }
                    }
                default: {
                    return {
                        recommended_n_main: rb.recommended_n_main,
                        recommended_p_main: rb.recommended_p_main,
                        recommended_k_main: rb.recommended_k_main,
                        recommended_n_sowing: rb.recommended_n_sowing,
                        recommended_p_sowing: rb.recommended_p_sowing,
                        recommended_k_sowing: rb.recommended_k_sowing,
                        recommended_n_feeding: rb.recommended_n_feeding,
                        recommended_p_feeding: rb.recommended_p_feeding,
                        recommended_k_feeding: rb.recommended_k_feeding
                    }
                }
            }
        }
    }

    const onSubmit = (fertilizers = selectedFertilizers) => {
        const values = getDataForSend(fertilizers)
        distribution.id
            ? dispatch(updateDistribution({ id: distribution.id, values }))
            : dispatch(addDistribution({
                calculate: {
                    id: calculator.id,
                    name: calculator.name,
                    calculator_type: calculator.calculator_type
                },
                values
            }))
    }

    const getDataForSend = (fertilizersValues) => {
        const fertilizers = fertilizersValues.map(fertilizer => ({
            fertilizer: fertilizer.id
        }))
        const recommended = getRecommended()
        const values = {
            distribution_method: 1,
            method: distributionType,
            fertilizers,
            ...recommended
        }
        return values
    }

    return (
        <div>
            {!isCompleted && (
                <Row style={{ marginBottom: '20px' }} gutter={20}>
                    <Col>
                        <Button onClick={() => showModal(false)}
                            disabled={selectedFertilizers.length === 7 || isCompleted}
                            type="primary">
                            <PlusCircleOutlined />{t('add')}
                        </Button>
                    </Col>
                    <Col>
                        <div className={cn('info')}>
                            <div className={cn('info__inner')}>{t('fertilizers limit')}</div>
                        </div>
                    </Col>
                </Row>
            )}

            {selectedFertilizers.length > 0 && (
                <div className={cn('row-table')}>
                    <Row className={cn('row-head')}>
                        <Col span={10} className={cn('row-table__label')}>{t('title')}</Col>
                        <Col span={3} className={cn('row-table__description')}>{t('cost')}</Col>
                        <Col span={3} className={cn('row-table__description')}>N, %</Col>
                        <Col span={3} className={cn('row-table__description')}>P, %</Col>
                        <Col span={3} className={cn('row-table__description')}>K, %</Col>
                        <Col span={1} className={cn('row-table__icon')} />
                        <Col span={1} className={cn('row-table__icon')} />
                    </Row>
                    {
                        selectedFertilizers.map((fertilizer, i) => (
                            <Row className={cn('row-table__withborder')} key={fertilizer.name + i}>
                                <Col span={10} className={cn('row-table__label')}>
                                    {fertilizer.name}
                                </Col>
                                <Col span={3} className={cn('row-table__description')}>
                                    {fertilizer.avg_price?.toLocaleString('ru-RU')}
                                </Col>
                                <Col span={3} className={cn('row-table__description')}>
                                    {fertilizer.content_n}
                                </Col>
                                <Col span={3} className={cn('row-table__description')}>
                                    {fertilizer.content_p}
                                </Col>
                                <Col span={3} className={cn('row-table__description')}>
                                    {fertilizer.content_k}
                                </Col>
                                <Col span={1} className={cn('row-table__icon')}>
                                    <QuestionCircleOutlined onClick={() => showFertilizerInfo(fertilizer.id)} />
                                </Col>
                                <Col span={1} className={cn('row-table__icon')}>
                                    <DeleteOutlined onClick={() => deleteFertilizer(fertilizer.id)} />
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            )}

            {!!distribution?.related_fertilizers?.length && distributionFertilizers.map((step, i) => (
                <Form form={form} key={step.title + i} onValuesChange={onValuesChange} className={cn('row-table')}>
                    <TableHead t={t} cn={cn} title={step.title} />
                    {
                        step.fertilizers.map((fertilizer, i) => (
                            <TableRow cn={cn}
                                key={`${step.title + i}table`}
                                isCompleted={isCompleted}
                                t={t}
                                fertilizer={fertilizer}
                                step={step.title} />
                        ))
                    }
                    <Form.Item />
                </Form>
            ))}
            {!!distribution?.related_fertilizers?.length && totalDistributionFertilizers.map((step, i) => (
                <div className={cn('row-table')} key={step.title + i}>
                    <TableHead t={t} cn={cn} title={step.title} />
                    {
                        step.fertilizers.map((fertilizer, i) => (
                            <TableRow cn={cn}
                                key={`${step.title}${i}table`}
                                isCompleted={isCompleted}
                                t={t}
                                fertilizer={fertilizer}
                                step={step.title} />
                        ))
                    }
                    <Form.Item />
                </div>
            ))}
            <Modal title=""
                visible={visibleFertilizer}
                onCancel={hideModalFertilizer}>
                {loadingFertilizer ? <Spin /> : <Fertilizer onCancel={hideModalFertilizer} />}
            </Modal>
            <Modal title={t('choose fertilizer')}
                visible={visibleFertilizersTable}
                onCancel={hideModalFertilizersTable}>
                <FertilizersTable setFertilizers={setFertilizers}
                    onCancel={hideModalFertilizersTable}
                    showInfo={showFertilizerInfo}
                    selectedFertilizers={selectedFertilizers} />
            </Modal>
        </div>
    )
}

const TableHead = ({
    cn, t, title
}) => (
    <Row className={cn('row-head')}>
        <Col span={10} className={cn('row-table__label')}>{t(title)}</Col>
        <Col span={3} className={cn('row-table__description')}>{t('N')}</Col>
        <Col span={3} className={cn('row-table__description')}>{t('P')}</Col>
        <Col span={3} className={cn('row-table__description')}>{t('K')}</Col>
        <Col span={3} className={cn('row-table__description')}>{t('phys')}</Col>
    </Row>
)

const TableRow = ({
    cn, fertilizer, step, isCompleted
}) => (
    <Row className={cn(fertilizer.type)}>
        <Col span={10} className={cn('row-table__label')}>{fertilizer.name}</Col>
        <Col span={3} className={cn('row-table__description')}>{fertilizer.actual_n}</Col>
        <Col span={3} className={cn('row-table__description')}>{fertilizer.actual_p}</Col>
        <Col span={3} className={cn('row-table__description')}>{fertilizer.actual_k}</Col>
        <Col span={3} className={cn('row-table__description')}>
            {fertilizer.id > 0 && !(fertilizer.total >= 0) && (
                <Form.Item name={`${fertilizer.id}_${step}`}>
                    <InputNumber
                        min={0}
                        step={1}
                        disabled={isCompleted}
                        decimalSeparator=","
                        placeholder="" />
                </Form.Item>
            )}
            {fertilizer.total?.toLocaleString('Ru-ru')}
        </Col>
    </Row>
)

const ToolTip = ({ current, normal }) => (
    <Tooltip title={`Рекомендуемое суммарное значение првышено на ${(current - normal)?.toFixed(2)}`}>
        <InfoCircleOutlined style={{ color: 'red', marginLeft: '8px' }} />
    </Tooltip>
)

const STEPS = ['main', 'sowing', 'feeding']
