import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Icon, {
    PlusCircleOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    Col, Form, InputNumber, Row, Spin, Tooltip, Radio, Popover, Select, Table, Button
} from 'antd'

import { useClassName } from 'utils/cn'
import SwitchFirstLogo from '../../../../../public/images/leafIcon1.svg'
import SwitchSecondLogo from '../../../../../public/images/leafIcon2.svg'

import {
    // Button,
    Input
} from '../../../../components'

import { actions, selectors } from '../../../../models'

import './style.less'

import Modal from './modal'
import Fertilizer from './fertilizer'
import FertilizersTable from './fertilizers-table'
import { toFixed } from '../../../../utils/utils'
import { curDistributionType, curCalculatorIdForCalculate } from '../../../../reducers/view/selectors'
import QuestionCircle from '../../../../../public/images/question-circle.svg'
import { setCurDistributionCalculate } from '../../../../reducers/view/actions'
import { updateFertilizerPrice } from '../../../../models/item-distribution/actions'
import { getCalculatorMordovia, getCalculatorsMordovia } from '../../../../models/mordovia/actions'
import { getUser } from '../../../../models/account/selectors'
import { getCalculatorsMordoviaObject, getCurrentCalculatorMordovia } from '../../../../models/mordovia/selectors'
import { makeParameterSpread } from '../../../../models/recommendation-registries/actions'

const {
    isLoadingFertilizer,
    isCalculateCompleted
} = selectors

const {
    updateFieldDistribution,
    updateDistributionGroupCalculate,
    getFertilizer
} = actions


export default ({
    calculator, distribution, normative, nutrition, vegaKey, isExpert = false, allowable = false
}) => {
    const distributionType = useSelector(state => curDistributionType(state))
    const calculatorId = useSelector(state => curCalculatorIdForCalculate(state))
    const isCompleted = useSelector(state => isCalculateCompleted(state))
    const loadingFertilizer = useSelector(state => isLoadingFertilizer(state))
    const user = useSelector(state => getUser(state))

    const isLaborant = user?.groups?.includes('Laborant')

    const calculatorOne = useSelector(state => getCurrentCalculatorMordovia(state))

    const dispatch = useDispatch()
    const { t } = useTranslation('distribution')
    const cn = useClassName('calculator')
    const match = useRouteMatch()
    const { id, year } = match.params
    const history = useHistory()
    const [form] = Form.useForm()
    const [visibleFertilizer, setVisibleFertilizer] = useState(false)
    const [visibleFertilizersTable, setVisibleFertilizersTable] = useState(false)
    const [selectedFertilizers, setSelectedFertilizers] = useState([])
    const [distributionFertilizers, setDistributionFertilizers] = useState([])
    const [totalDistributionFertilizers, setTotalDistributionFertilizers] = useState([])
    const [switchMode, setSwitchMode] = useState('')

    useEffect(() => {
        if (distributionType && calculatorId === calculator.id) {
            onCalculate([...selectedFertilizers])
        }
    }, [distributionType])

    useEffect(() => {
        if (distribution) {
            setSwitchMode(distribution.method || null)

            const { related_fertilizers } = distribution

            const values = {}
            related_fertilizers?.forEach(fertilizer => {
                values[`${fertilizer.fertilizer.id}_main`] = fertilizer.main
                values[`${fertilizer.fertilizer.id}_sowing`] = fertilizer.sowing
                const feedingKeys = Object.keys(fertilizer.feedings)
                feedingKeys.forEach(feedKey => {
                    values[`${fertilizer.fertilizer.id}_${feedKey}`] = fertilizer.feedings[feedKey]
                })
            })
            form.setFieldsValue(values)
            const fertilizers = []
            related_fertilizers?.forEach((fertilizer) => {
                fertilizers.push({
                    main: fertilizer.main,
                    sowing: fertilizer.sowing,
                    total: fertilizer.total,
                    price: fertilizer.price,
                    total_price_per_hectare: fertilizer.total_price_per_hectare,
                    total_price: fertilizer.total_price,
                    bigId: fertilizer.id,
                    ...fertilizer.feedings,
                    ...fertilizer.fertilizer
                })
            })
            setSelectedFertilizers([...fertilizers])
            const recommended = getRecommended()
            const distributionWithProps = { ...distribution, ...recommended }

            setDistributionFertilizers(converterFertilizer(distributionWithProps))
            setTotalDistributionFertilizers(totalFertilizer(distributionWithProps))
        }
    }, [distribution])

    const setFertilizers = (fertilizers) => {
        onSubmit(fertilizers)
    }

    const getFeedingKeys = (recommendedFeed, actualFeed) => {
        const allKeys = Object.keys(recommendedFeed)
        Object.keys(actualFeed).forEach(feedKey => {
            if (!allKeys.includes(feedKey)) {
                allKeys.push(feedKey)
            }
        })
        return allKeys
    }

    const converterFertilizer = (distribution) => {
        const arr = []

        STEPS.forEach(step => {
            if (step === 'feeding') {
                const recommendedFeeding = distribution.recommended_feeding
                const actualFeeding = distribution.actual_feeding
                const feedingFertilizers = distribution.related_fertilizers
                const feedingStages = getFeedingKeys(recommendedFeeding, actualFeeding)

                feedingStages.forEach((feedingStageKey) => {
                    const total = { actual_n: 0, actual_p: 0, actual_k: 0, actual_s: 0 }
                    const fertilizers = []
                    fertilizers.push({
                        id: 0,
                        name: t('recommended'),
                        type: 'row-bold',
                        actual_n: toFixed(recommendedFeeding[feedingStageKey].recommended_n_feeding)?.toLocaleString('ru-RU'),
                        actual_p: toFixed(recommendedFeeding[feedingStageKey].recommended_p_feeding)?.toLocaleString('ru-RU'),
                        actual_k: toFixed(recommendedFeeding[feedingStageKey].recommended_k_feeding)?.toLocaleString('ru-RU'),
                        actual_s: toFixed(recommendedFeeding[feedingStageKey].recommended_s_feeding)?.toLocaleString('ru-RU'),
                    })
                    const recommendedFeedingHelper = {
                        actual_n: toFixed(recommendedFeeding[feedingStageKey].recommended_n_feeding),
                        actual_p: toFixed(recommendedFeeding[feedingStageKey].recommended_p_feeding),
                        actual_k: toFixed(recommendedFeeding[feedingStageKey].recommended_k_feeding),
                        actual_s: toFixed(recommendedFeeding[feedingStageKey].recommended_s_feeding)
                    }
                    feedingFertilizers?.forEach(fertilizer => {
                        const curFertilizer = fertilizer.fertilizer
                        if (curFertilizer.id) {
                            const actual = {};
                            ['n', 'p', 'k', 's'].forEach(item => {
                                actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer.feedings[feedingStageKey]) / 100) ?? 0
                                total[`actual_${item}`] += actual[`actual_${item}`]
                            })
                            fertilizers.push({
                                id: curFertilizer.id,
                                name: curFertilizer.name,
                                actual_n: toFixed(actual?.actual_n)?.toLocaleString('ru-RU'),
                                actual_p: toFixed(actual?.actual_p)?.toLocaleString('ru-RU'),
                                actual_k: toFixed(actual?.actual_k)?.toLocaleString('ru-RU'),
                                actual_s: toFixed(actual?.actual_s)?.toLocaleString('ru-RU'),
                                total: toFixed(fertilizer.feedings[feedingStageKey])
                            })
                        }
                    })
                    if (Math.round(total.actual_n) > Math.round(recommendedFeedingHelper.actual_n)) {
                        total.actual_n = <>{toFixed(total?.actual_n)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_n} normal={recommendedFeedingHelper.actual_n} /></>
                    } else {
                        total.actual_n = toFixed(total.actual_n)?.toLocaleString('ru-RU')
                    }
                    if (Math.round(total.actual_p) > Math.round(recommendedFeedingHelper.actual_p)) {
                        total.actual_p = <>{toFixed(total?.actual_p)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_p} normal={recommendedFeedingHelper.actual_p} /></>
                    } else {
                        total.actual_p = toFixed(total.actual_p)?.toLocaleString('ru-RU')
                    }
                    if (Math.round(total.actual_k) > Math.round(recommendedFeedingHelper.actual_k)) {
                        total.actual_k = <>{toFixed(total?.actual_k)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_k} normal={recommendedFeedingHelper.actual_k} /></>
                    } else {
                        total.actual_k = toFixed(total.actual_k)?.toLocaleString('ru-RU')
                    }

                    if (Math.round(total.actual_s) > Math.round(recommendedFeedingHelper.actual_s)) {
                        total.actual_s = <>{toFixed(total?.actual_s)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_s} normal={recommendedFeedingHelper.actual_s} /></>
                    } else {
                        total.actual_s = toFixed(total.actual_s)?.toLocaleString('ru-RU')
                    }
                    fertilizers.push({
                        id: 0, type: 'row-bold', name: t('total'), ...total
                    })
                    arr.push({ title: feedingStageKey, fertilizers })
                })
            } else {
                const total = { actual_n: 0, actual_p: 0, actual_k: 0, actual_s: 0 }
                const fertilizers = []
                fertilizers.push({
                    id: 0,
                    name: t('recommended'),
                    type: 'row-bold',
                    actual_n: toFixed(distribution[`recommended_n_${step}`])?.toLocaleString('ru-RU'),
                    actual_p: toFixed(distribution[`recommended_p_${step}`])?.toLocaleString('ru-RU'),
                    actual_k: toFixed(distribution[`recommended_k_${step}`])?.toLocaleString('ru-RU'),
                    actual_s: toFixed(distribution[`recommended_s_${step}`])?.toLocaleString('ru-RU')
                })
                distribution.related_fertilizers?.forEach(fertilizer => {
                    const curFertilizer = fertilizer.fertilizer
                    if (curFertilizer.id) {
                        const actual = {};
                        ['n', 'p', 'k', 's'].forEach(item => {
                            actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer[step]) / 100) ?? 0
                            total[`actual_${item}`] += actual[`actual_${item}`]
                        })
                        fertilizers.push({
                            id: curFertilizer.id,
                            name: curFertilizer.name,
                            actual_n: toFixed(actual?.actual_n)?.toLocaleString('ru-RU'),
                            actual_p: toFixed(actual?.actual_p)?.toLocaleString('ru-RU'),
                            actual_k: toFixed(actual?.actual_k)?.toLocaleString('ru-RU'),
                            actual_s: toFixed(actual?.actual_s)?.toLocaleString('ru-RU'),
                            total: toFixed(fertilizer[step])
                        })
                    }
                })

                if (Math.round(total.actual_n) > Math.round(distribution[`recommended_n_${step}`])) {
                    total.actual_n = <>{toFixed(total?.actual_n)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_n} normal={distribution[`recommended_n_${step}`]} /></>
                } else {
                    total.actual_n = toFixed(total.actual_n)?.toLocaleString('ru-RU')
                }
                if (Math.round(total.actual_p) > Math.round(distribution[`recommended_p_${step}`])) {
                    total.actual_p = <>{toFixed(total?.actual_p)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_p} normal={distribution[`recommended_p_${step}`]} /></>
                } else {
                    total.actual_p = toFixed(total.actual_p)?.toLocaleString('ru-RU')
                }
                if (Math.round(total.actual_k) > Math.round(distribution[`recommended_k_${step}`])) {
                    total.actual_k = <>{toFixed(total?.actual_k)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_k} normal={distribution[`recommended_k_${step}`]} /></>
                } else {
                    total.actual_k = toFixed(total.actual_k)?.toLocaleString('ru-RU')
                }

                if (Math.round(total.actual_s) > Math.round(distribution[`recommended_s_${step}`])) {
                    total.actual_s = <>{toFixed(total?.actual_s)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_s} normal={distribution[`recommended_s_${step}`]} /></>
                } else {
                    total.actual_s = toFixed(total.actual_s)?.toLocaleString('ru-RU')
                }
                fertilizers.push({
                    id: 0, type: 'row-bold', name: t('total'), ...total
                })
                arr.push({ title: step, fertilizers })
            }
        })
        return arr
    }

    const totalFertilizer = (distribution) => {
        const arr = []
        const fertilizers = []
        const totalRecommended = { actual_n: 0, actual_p: 0, actual_k: 0, actual_s: 0 }
        const total = { actual_n: 0, actual_p: 0, actual_k: 0, actual_s: 0 }
        STEPS.forEach(step => {
            totalRecommended.actual_n += distribution[`recommended_n_${step}`]
            totalRecommended.actual_p += distribution[`recommended_p_${step}`]
            totalRecommended.actual_k += distribution[`recommended_k_${step}`]
            totalRecommended.actual_s += distribution[`recommended_s_${step}`]
        })
        fertilizers.push({
            id: 0,
            type: 'row-bold-green',
            name: t('recommended'),
            actual_n: toFixed(totalRecommended?.actual_n)?.toLocaleString('ru-RU'),
            actual_p: toFixed(totalRecommended?.actual_p)?.toLocaleString('ru-RU'),
            actual_k: toFixed(totalRecommended?.actual_k)?.toLocaleString('ru-RU'),
            actual_s: toFixed(totalRecommended?.actual_s)?.toLocaleString('ru-RU'),
        })
        distribution.related_fertilizers?.forEach(fertilizer => {
            const curFertilizer = fertilizer.fertilizer
            if (curFertilizer.id) {
                const actual = {};
                ['n', 'p', 'k', 's'].forEach(item => {
                    actual[`actual_${item}`] = ((curFertilizer[`content_${item}`] * fertilizer.total) / 100) ?? 0
                    total[`actual_${item}`] += actual[`actual_${item}`]
                })
                fertilizers.push({
                    id: curFertilizer.id,
                    name: curFertilizer.name,
                    total: fertilizer.total,
                    total_price: fertilizer?.total_price,
                    actual_n: toFixed(actual?.actual_n)?.toLocaleString('ru-RU'),
                    actual_p: toFixed(actual?.actual_p)?.toLocaleString('ru-RU'),
                    actual_k: toFixed(actual?.actual_k)?.toLocaleString('ru-RU'),
                    actual_s: toFixed(actual?.actual_s)?.toLocaleString('ru-RU'),
                })
            }
        })
        if (Math.round(total.actual_n) > Math.round(totalRecommended.actual_n)) {
            total.actual_n = <>{toFixed(total?.actual_n)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_n} normal={totalRecommended.actual_n} /></>
        } else {
            total.actual_n = toFixed(total.actual_n)?.toLocaleString('ru-RU')
        }
        if (Math.round(total.actual_p) > Math.round(totalRecommended.actual_p)) {
            total.actual_p = <>{toFixed(total?.actual_p)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_p} normal={totalRecommended.actual_p} /></>
        } else {
            total.actual_p = toFixed(total.actual_p)?.toLocaleString('ru-RU')
        }
        if (Math.round(total.actual_k) > Math.round(totalRecommended.actual_k)) {
            total.actual_k = <>{toFixed(total?.actual_k)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_k} normal={totalRecommended.actual_k} /></>
        } else {
            total.actual_k = toFixed(total.actual_k)?.toLocaleString('ru-RU')
        }

        if (Math.round(total.actual_s) > Math.round(totalRecommended.actual_s)) {
            total.actual_s = <>{toFixed(total?.actual_s)?.toLocaleString('ru-RU')} <ToolTip current={total.actual_s} normal={totalRecommended.actual_s} /></>
        } else {
            total.actual_s = toFixed(total.actual_s)?.toLocaleString('ru-RU')
        }
        fertilizers.push({
            id: 0, type: 'row-bold-green', name: t('total'), ...total
        })
        arr.push({ title: t('total'), fertilizers })

        return arr
    }

    const onValuesChange = (value, values) => {
        const feedingHelper = {}
        Object.keys(values).forEach(valueKey => {
            const keySplit = valueKey.split('_')
            if (keySplit[1] !== 'sowing' && keySplit[1] !== 'main') {
                if (!feedingHelper[keySplit[0]]) {
                    feedingHelper[keySplit[0]] = {}
                }
                feedingHelper[keySplit[0]][keySplit[1]] = parseFloat(values[valueKey])
            }
        })

        const fertilizers = selectedFertilizers.map(fertilizer => ({
            fertilizer: fertilizer.id,
            main: parseFloat(values[`${fertilizer.id}_main`]) ?? fertilizer.main ?? 0,
            sowing: parseFloat(values[`${fertilizer.id}_sowing`]) ?? fertilizer.sowing ?? 0,
            feeding: feedingHelper[fertilizer.id] ?? fertilizer.feedings
        }))
        const recommended = getRecommended()
        const sendValues = {
            // distribution_method: 1,
            fertilizers,
            ...recommended
        }
        distribution.id && dispatch(updateDistributionGroupCalculate({ id: distribution.id, values: sendValues }))
    }

    const showModal = () => {
        setVisibleFertilizersTable(true)
    }

    const showFertilizerInfo = (fertilizerId) => {
        if (!isCompleted) {
            dispatch(getFertilizer(fertilizerId))
            setVisibleFertilizer(true)
        }
    }

    const deleteFertilizer = (fertilizerId) => {
        if (!isCompleted) {
            const fertilizers = [...selectedFertilizers]
            const indexOfDeleted = fertilizers.findIndex(item => item.id === fertilizerId)
            fertilizers.splice(indexOfDeleted, 1)
            onSubmit(fertilizers)
        }
    }

    const hideModalFertilizer = () => {
        setVisibleFertilizer(false)
    }

    const hideModalFertilizersTable = () => {
        setVisibleFertilizersTable(false)
    }

    const closeCalculator = () => {
        history.push('/calculators')
    }

    const getRecommended = () => {
        switch (distributionType) {
            case 'expert':
                {
                    return {
                        recommended_n_main: normative.expert_n_main,
                        recommended_p_main: normative.expert_p_main,
                        recommended_k_main: normative.expert_k_main,
                        recommended_s_main: normative.expert_k_main,
                        recommended_n_sowing: normative.expert_n_sowing,
                        recommended_p_sowing: normative.expert_p_sowing,
                        recommended_k_sowing: normative.expert_k_sowing,
                        recommended_s_sowing: normative.expert_k_sowing,
                        recommended_n_feeding: normative.expert_n_feeding,
                        recommended_p_feeding: normative.expert_p_feeding,
                        recommended_k_feeding: normative.expert_k_feeding,
                        recommended_s_feeding: normative.expert_k_feeding
                    }
                }
            case 'nutrition':
                {
                    return {
                        recommended_n_main: nutrition.recommended_n_main,
                        recommended_p_main: nutrition.recommended_p_main,
                        recommended_k_main: nutrition.recommended_k_main,
                        recommended_s_main: nutrition.recommended_k_main,
                        recommended_n_sowing: nutrition.recommended_n_sowing,
                        recommended_p_sowing: nutrition.recommended_p_sowing,
                        recommended_k_sowing: nutrition.recommended_k_sowing,
                        recommended_s_sowing: nutrition.recommended_k_sowing,
                        recommended_n_feeding: nutrition.recommended_n_feeding,
                        recommended_p_feeding: nutrition.recommended_p_feeding,
                        recommended_k_feeding: nutrition.recommended_k_feeding,
                        recommended_s_feeding: nutrition.recommended_k_feeding
                    }
                }
            case 'normative':
            default:
                {
                    return {
                        recommended_n_main: normative.recommended_n_main,
                        recommended_p_main: normative.recommended_p_main,
                        recommended_k_main: normative.recommended_k_main,
                        recommended_s_main: normative.recommended_k_main,
                        recommended_n_sowing: normative.recommended_n_sowing,
                        recommended_p_sowing: normative.recommended_p_sowing,
                        recommended_k_sowing: normative.recommended_k_sowing,
                        recommended_s_sowing: normative.recommended_k_sowing,
                        recommended_n_feeding: normative.recommended_n_feeding,
                        recommended_p_feeding: normative.recommended_p_feeding,
                        recommended_k_feeding: normative.recommended_k_feeding,
                        recommended_s_feeding: normative.recommended_k_feeding
                    }
                }
        }
    }

    const onSubmit = (fertilizers = selectedFertilizers) => {
        const values = getDataForSend(fertilizers)
        values.year = year
        dispatch(updateFieldDistribution({ vega_key: vegaKey, body: values }))
    }

    const onCalculate = (fertilizers = selectedFertilizers) => {
        const values = getDataForSend(fertilizers)
        distribution.id && dispatch(updateDistributionGroupCalculate({ id: distribution.id, values }))
    }

    const getDataForSend = (fertilizersValues) => {
        const fertilizers = fertilizersValues.map(fertilizer => ({
            fertilizer: fertilizer.id
        }))
        const recommended = getRecommended()
        const values = {
            distribution_method: 1,
            method: distributionType,
            fertilizers,
            ...recommended
        }
        return values
    }

    const firstPopoverContent = (
        <div>
            <p
                style={{ maxWidth: '320px' }}>
                {t('get fertilizer')} {t('normative base')}
            </p>
        </div>
    )
    const secondPopoverContent = (
        <div>
            <p
                style={{ maxWidth: '370px' }}>
                {t('get fertilizer')}, {t('agrochemical base')}
            </p>
        </div>
    )
    const switchOptions = [
        {
            label: (
                <Popover
                    content={firstPopoverContent}
                    placement="bottom">
                    <Icon
                        style={{
                            fontSize: '38px',
                            margin: 'auto'
                        }}
                        component={SwitchFirstLogo} />
                </Popover>
            ),
            value: 'normative'
        },
        {
            label: (
                <Popover
                    content={secondPopoverContent}
                    placement="bottom"
                    arrowPointAtCenter>
                    <Icon
                        style={{
                            fontSize: '38px',
                            margin: 'auto'
                        }}
                        component={SwitchSecondLogo} />
                </Popover>
            ),
            value: 'nutrition'
        }
    ]

    const sendData = (type) => {
        if (nutrition.id ?? type === 'nutrition') {
            dispatch(setCurDistributionCalculate({ type, calculator: calculator.id }))
        } else if (type === 'normative') {
            dispatch(setCurDistributionCalculate({ type, calculator: calculator.id }))
        }
    }

    const handleSwitchChange = (evt) => {
        setSwitchMode(evt.target.value)
        sendData(evt.target.value)
    }

    const handleUpdateFertilizerPrice = (fertilizer, event) => {
        if (fertilizer?.bigId && calculatorOne?.user) {
            dispatch(updateFertilizerPrice({
                id: fertilizer?.bigId,
                values: {
                    price: event,
                    owner: calculatorOne?.user
                }
            }))
        }

        const { id, year } = match.params
        if (id && year) {
            setTimeout(() => dispatch(getCalculatorMordovia({ id, year })), 1000)
        }
    }

    const [manyFieldModal, setManyFieldModal] = useState(false)
    const [selectedManyFields, setSelectedManyFields] = useState([])

    const [fieldsPage, setFieldsPage] = useState(1)
    const [fieldsPageSize, setFieldsPageSize] = useState(10)
    const [fieldsDataset, setFieldsDataset] = useState([])
    const [selectedFieldYear, setSelectedFieldYear] = useState([])
    const [selectedFieldOwner, setSelectedFieldOwner] = useState([])
    const [selectedFieldNum, setSelectedFieldNum] = useState(null)
    const [selectedFieldCulture, setSelectedFieldCulture] = useState([])
    const [rawFieldYears, setRawFieldYears] = useState([])
    const [newFieldEntity, setNewFieldEntity] = useState({})
    const [fldYears, setFldYears] = useState([])
    const [filteredFieldYears, setFilteredFieldYears] = useState([])

    const calculatorsList = useSelector(state => getCalculatorsMordoviaObject(state))

    useEffect(() => {
        const yrsArray = []
        //
        fieldsDataset?.forEach(field => {
            field?.year_group?.years?.forEach(yr => {
                const insertableYear = {
                    id: yr?.id,
                    vega_key: field?.vega_key,
                    field_num: field?.field_num,
                    year: yr?.year,
                    culture: field?.culture?.name,
                }
                yrsArray.push(insertableYear)
            })
        })
        setFldYears(yrsArray)
    }, [fieldsDataset])

    useEffect(() => {
        const { count, results, next } = calculatorsList
        if (results?.length > 0) {
            const resultsAddition = []
            results?.forEach(resultEntity => {
                const filteredResults = fieldsDataset?.filter(x => x.id === resultEntity.id)
                if (filteredResults?.length < 1) {
                    resultsAddition.push(resultEntity)
                }
            })
            if (fieldsDataset?.length < 1) {
                setFieldsDataset([...fieldsDataset, ...resultsAddition])
            }
        }
        if (next?.length > 0) {
            setFieldsPage(fieldsPage + 1)
        }
    }, [calculatorsList])

    const handleGetFields = () => {
        const page_size_url = `page_size=${1000}`
        const page_url = `page=${fieldsPage}`
        dispatch(getCalculatorsMordovia(`?${page_size_url}&${page_url}${`&filter={"user":${calculatorOne?.user}}`}`))
    }

    useEffect(() => {
        handleGetFields()
    }, [fieldsPage])

    useEffect(() => {
        handleGetFields()
    }, [fieldsPageSize])

    const openManyField = () => {
        setManyFieldModal(true)
    }

    const [selectedTableItems, setSelectedTableItems] = useState([])

    const handleManyFields = () => {
        dispatch(makeParameterSpread({
            registerId: calculator?.recommendation_register,
            values: {
                field_years: selectedTableItems?.map(x => x.id)
            }
        }))
        setSelectedTableItems([])
        setManyFieldModal(false)
    }

    const onSelectYr = (idKeys, selValues) => {
        setSelectedTableItems(selValues)
    }

    const rowSelection = {
        selectedRowKeys: selectedTableItems?.map(x => x.id),
        onChange: onSelectYr,
    }

    const yearColumns = [
        {
            title: 'Поле',
            dataIndex: 'vega_key',
            key: 'vega_key',
        },
        {
            title: 'Год',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Номер поля',
            dataIndex: 'field_num',
            key: 'field_num',
        },
        {
            title: 'Культура',
            dataIndex: 'culture',
            key: 'culture',
        },
    ]

    const [yearFilters, setYearFilters] = useState([])
    const [cultureFilters, setCultureFilters] = useState([])

    useEffect(() => {
        setFilteredFieldYears(fldYears)
        const yrs = []
        const cltrs = []

        fldYears?.forEach(fld => {

            if (!yrs.includes(fld.year)) {
                yrs.push(fld.year)
            }

            if (!cltrs.includes(fld.culture) && fld?.culture?.length > 0) {
                cltrs.push(fld.culture)
            }
        })

        setYearFilters(yrs.map(x => {
            return {
                label: x,
                value: x
            }
        }))
        setCultureFilters(cltrs.map(x => {
            return {
                label: x,
                value: x
            }
        }))
    }, [fldYears])

    useEffect(() => {
        let filtrd

        if (selectedFieldCulture?.length <= 0 && selectedFieldYear?.length <= 0) {
            filtrd = [...fldYears]
        }

        if (selectedFieldCulture?.length > 0 && selectedFieldYear?.length === 0) {
            filtrd = [...fldYears].filter(yr => selectedFieldCulture?.includes(yr.culture))
        }

        if (selectedFieldCulture?.length === 0 && selectedFieldYear?.length > 0) {
            filtrd = [...fldYears].filter(yr => selectedFieldYear?.includes(yr.year))
        }

        if (selectedFieldCulture?.length > 0 && selectedFieldYear?.length > 0) {
            filtrd = [...fldYears].filter(yr => selectedFieldYear?.includes(yr.year) && selectedFieldCulture?.includes(yr.culture))
        }

        setFilteredFieldYears([...filtrd])
    }, [selectedFieldCulture, selectedFieldYear])

    return (
        <div>
            {!isCompleted && (
                <Row style={{ marginBottom: '20px' }} gutter={20}>
                    <Col>
                        <Button onClick={() => showModal(false)}
                            disabled={selectedFertilizers.length === 7 || isCompleted}
                            type="secondary">
                            <PlusCircleOutlined />{t('add')}
                        </Button>
                    </Col>
                    <Col>
                        <div className={cn('info')}>
                            <div className={cn('info__inner')}>{t('fertilizers limit')}</div>
                        </div>
                    </Col>
                </Row>
            )}

            <Row>
                <Col span={6}>
                    <Radio.Group
                        options={switchOptions}
                        size="large"
                        onChange={handleSwitchChange}
                        value={switchMode}
                        optionType="button"
                        buttonStyle="solid" />
                </Col>

                {isExpert && (
                    <>
                        <Col span={14} />
                        <Col span={4}>
                            <Button onClick={openManyField}>
                                {t('many field button')}
                            </Button>
                        </Col>

                        <Modal
                            title={t('many fields title')}
                            visible={manyFieldModal}
                            onOk={handleManyFields}
                            onCancel={() => {
                                setManyFieldModal(false)
                                setSelectedManyFields([])
                            }}
                            footer={[]}>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder={t('select year placeholder')}
                                        options={yearFilters}
                                        onChange={setSelectedFieldYear} />
                                </Col>
                                <Col span={6}>
                                    <Select
                                        mode="multiple"
                                        placeholder={t('select culture placeholder')}
                                        options={cultureFilters}
                                        onChange={setSelectedFieldCulture} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '15px' }}>
                                <Table rowKey="id" rowSelection={rowSelection} columns={yearColumns} dataSource={filteredFieldYears} style={{ width: '100%' }} />
                            </Row>
                            <Row style={{ marginTop: '25px' }} gutter={24}>
                                <Col span={16} />
                                <Col span={4}>
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={() => {
                                            setManyFieldModal(false)
                                            setSelectedManyFields([])
                                        }}
                                        type="secondary">
                                        {t('cancel')}
                                    </Button>
                                </Col>
                                {/* <Col span={1} /> */}
                                <Col span={4}>
                                    <Button style={{ width: "100%" }} onClick={handleManyFields} type="primary">
                                        {t('ok')}
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                    </>
                )}
            </Row>

            {selectedFertilizers.length > 0 && (
                <div className={cn('row-table')}>
                    <Row className={cn('row-head')}>
                        <Col span={9} className={cn('row-table__label')}>{t('title')}</Col>
                        <Col span={isExpert ? 2 : 3} className={cn('row-table__description')}>N, %</Col>
                        <Col span={isExpert ? 2 : 3} className={cn('row-table__description')}>P, %</Col>
                        <Col span={2} className={cn('row-table__description')}>K, %</Col>
                        <Col span={2} className={cn('row-table__description')}>S, %</Col>
                        <Col span={2} className={cn('row-table__description')}>{t('price')}</Col>
                        <Col span={2} className={cn('row-table__description')}>{t('cost')}</Col>
                        <Col span={1} className={cn('row-table__icon')} />
                        <Col span={1} className={cn('row-table__icon')} />
                    </Row>
                    {
                        selectedFertilizers.map((fertilizer, i) => (
                            <Row className={cn('row-table__withborder')} key={fertilizer.name + i}>
                                <Col span={9} className={cn('row-table__label')}>
                                    {fertilizer.name}
                                </Col>
                                <Col span={2} className={cn('row-table__description')}>
                                    {fertilizer.content_n}
                                </Col>
                                <Col span={2} className={cn('row-table__description')}>
                                    {fertilizer.content_p}
                                </Col>
                                <Col span={2} className={cn('row-table__description')}>
                                    {fertilizer.content_k}
                                </Col>
                                <Col span={2} className={cn('row-table__description')}>
                                    {fertilizer.content_s}
                                </Col>
                                <Col span={2} className={cn('row-table__description')}>
                                    {(isExpert || isLaborant) ? (
                                        <InputNumber
                                            defaultValue={fertilizer?.price ?? fertilizer.avg_price?.toLocaleString('ru-RU')}
                                            onBlur={(event) => {
                                                event?.preventDefault()
                                                handleUpdateFertilizerPrice(fertilizer, event?.target?.value)
                                            }} />
                                    ) : (
                                        fertilizer?.price ?? fertilizer.avg_price?.toLocaleString('ru-RU')
                                    )}
                                </Col>
                                <Col span={3} className={cn('row-table__description')}>
                                    {fertilizer.total_price_per_hectare?.toLocaleString('ru-RU')}
                                </Col>
                                <Col span={1} className={cn('row-table__icon')}>
                                    <QuestionCircleOutlined
                                        onClick={() => showFertilizerInfo(fertilizer.id)} />
                                </Col>
                                <Col span={1} className={cn('row-table__icon')}>
                                    <DeleteOutlined
                                        onClick={() => deleteFertilizer(fertilizer.id)} />
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            )}

            {!!distribution?.related_fertilizers?.length && distributionFertilizers?.map((step, i) => (
                <>
                    <Form form={form} key={step.title + i} onValuesChange={onValuesChange} className={cn('row-table')}>
                        <TableHead
                            isLaborant={isExpert}
                            t={t}
                            cn={cn}
                            title={(step.title === 'main' || step.title === 'sowing') ? step.title : (`${step.title}, ${t('feeding units text')}`)} />
                        {
                            step.fertilizers.map((fertilizer, i) => (
                                <TableRow cn={cn}
                                    allowable={allowable}
                                    isLaborant={isLaborant}
                                    key={`${step.title + i}table`}
                                    isCompleted={isCompleted}
                                    t={t}
                                    indx={i}
                                    fullLength={step.fertilizers.length}
                                    isData
                                    fertilizer={fertilizer}
                                    step={step.title} />
                            ))
                        }
                        <Form.Item />
                    </Form>
                </>
            ))}

            {!!distribution?.related_fertilizers?.length && totalDistributionFertilizers.map((step, i) => (
                <div className={cn('row-table')} key={step.title + i}>
                    <TableHead t={t} cn={cn} title={step.title} isFinal={true} isLaborant={isExpert} />
                    {
                        step.fertilizers.map((fertilizer, i) => (
                            <TableRow cn={cn}
                                allowable={allowable}
                                isLaborant={isLaborant}
                                key={`${step.title}${i}table`}
                                isCompleted={isCompleted}
                                t={t}
                                isFinal={true}
                                fertilizer={fertilizer}
                                step={step.title} />
                        ))
                    }
                    <Form.Item />
                </div>
            ))}

            <Modal title=""
                visible={visibleFertilizer}
                onCancel={hideModalFertilizer}>
                {loadingFertilizer ? <Spin /> : <Fertilizer onCancel={hideModalFertilizer} />}
            </Modal>
            <Modal title={t('choose fertilizer')}
                visible={visibleFertilizersTable}
                onCancel={hideModalFertilizersTable}>
                <FertilizersTable setFertilizers={setFertilizers}
                    onCancel={hideModalFertilizersTable}
                    showInfo={showFertilizerInfo}
                    selectedFertilizers={selectedFertilizers} />
            </Modal>
        </div>
    )
}

const TableHead = ({
    cn, t, title, isFinal = false, isLaborant = false
}) => (
    <Row className={cn('row-head')}>
        <Col span={9} className={cn('row-table__label')}>{t(title)}</Col>
        <Col span={2} className={cn('row-table__description')}>{t('N')}</Col>
        <Col span={2} className={cn('row-table__description')}>{t('P')}</Col>
        <Col span={2} className={cn('row-table__description')}>{t('K')}</Col>
        <Col span={2} className={cn('row-table__description')}>{t('S')}</Col>
        <Col span={3} className={cn('row-table__description')}>{t('phys')}</Col>
        {isFinal && (
            <Col span={2} className={cn('row-table__description')}>{t('cost')}</Col>
        )}
    </Row>
)

// const { t } = useTranslation('distribution');

const TableRow = ({
    cn, fertilizer, step, isCompleted, indx, t, fullLength, isFinal = false, isLaborant, allowable
}) => (
    <Row className={cn(fertilizer.type)}>
        <Col span={9} className={cn('row-table__label')}>{fertilizer.name}</Col>
        <Col span={2} className={cn('row-table__description')}>{fertilizer.actual_n}</Col>
        <Col span={2} className={cn('row-table__description')}>{fertilizer.actual_p}</Col>
        <Col span={2} className={cn('row-table__description')}>{fertilizer.actual_k}</Col>
        <Col span={2} className={cn('row-table__description')}>{fertilizer.actual_s}</Col>
        <Col span={3} className={cn('row-table__description')}>
            {fertilizer && fertilizer.id > 0 && (
                <Form.Item name={`${fertilizer.id}_${step}`}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                        {(isFinal || !allowable) ? (
                            fertilizer?.total ?? 0
                        ) : (
                            <InputNumber
                                value={fertilizer?.total ?? 0}
                                min={0}
                                step={1}
                                onChange={val => console.log('VVV', val)}
                                disabled={isCompleted}
                                decimalSeparator=","
                                placeholder="" />
                        )}
                    </div>
                </Form.Item>
            )}
        </Col>
        {isFinal && (
            <Col span={2} className={cn('row-table__description')}>
                {fertilizer?.total_price?.toLocaleString('ru-RU')}
            </Col>
        )}
        {indx !== 0 && (indx < (fullLength - 1)) && allowable && (
            <Col span={1} className={cn('row-table__description')}>
                <Popover
                    style={{
                        marginLeft: '10px'
                    }}
                    content={(
                        <div>
                            <p
                                style={{ maxWidth: '220px' }}>
                                {t('service message')}
                            </p>
                        </div>
                    )}
                    placement="bottom">
                    <QuestionCircleOutlined />
                </Popover>
            </Col>
        )}
    </Row>
)

const ToolTip = ({ current, normal }) => (
    <Tooltip title={`Рекомендуемое суммарное значение првышено на ${(current - normal)?.toFixed(2)}`}>
        <InfoCircleOutlined style={{ color: 'red', marginLeft: '8px' }} />
    </Tooltip>
)

const STEPS = ['main', 'sowing', 'feeding']
